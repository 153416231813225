import React, { useState, useRef } from "react";
import { Paper, Typography, Button, Modal, Box, Grid } from "@mui/material";
import { showLicense } from "../api/endpoints";
import { UploadExcel } from "../api/endpoints";
import { toastErrorNotify } from "../helpers/toastNotify";
import { successNote } from "../helpers/toastNotify";
import "../index.css";
import apiClient from "../api/apiClient";
import Header from "../layout/Header";


const IntegrasjonPage = () => {
  const [license, setLicense] = useState("");
  const [open, setOpen] = useState(false);
  const [file, setFile] = useState(null);
  const fileInputRef = useRef(null);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleClickShowLicense = async (e) => {
    e.preventDefault();
    try {
      const response = await apiClient.get(showLicense);
      setLicense(response.data.data.license);
      handleOpen();
    } catch (err) {
      toastErrorNotify(err.response?.data?.message || "Det er en error!");
    }
  };


  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
    }
  };

  const handleFileUpload = async () => {
    console.log("1")

    if (!file) {
      toastErrorNotify("Vennligst velg en excel fil.");
      return;
    }

    const formData = new FormData();
    formData.append("file", file);

    try {
      console.log(UploadExcel)

      const response = await apiClient.post(UploadExcel, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      successNote("Filen er lastet opp!");
      // Burada ek işlemler yapabilirsiniz, örneğin formu temizlemek
      setFile(null);
    
      console.log(response);
      if(response?.data?.data?.alreadyClaimedUsers?.length > 0)
      {
        console.log(response.data.data.alreadyClaimedUsers);
        let claimedUsers = "Nevnte brukere tilhører allerede en annen organisasjon:";
        response.data.data.alreadyClaimedUsers.forEach((claimedUser) => {
          claimedUsers = claimedUsers + "\n• " + claimedUser.name;
        });
        window.alert(claimedUsers)
        
      }
    } catch (err) {
      toastErrorNotify(err.response?.data?.message || "Klarte ikke å laste opp filen. Vennligst verifiser innholdet og prøv igjen.");
    }
  };


  return (
    <main>
      <Header header={'Integrasjon'} headerSize={'h6'} className={'integrasjon'} />
      <Grid
        container
        maxWidth="md"
        rowSpacing={3}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        sx={{ mt: 1 }}
      >
        {/*<Paper sx={{ m: 4, p: 3 }}>*/}
        {/*  <Grid item xs={12}>*/}
        {/*    <Typography variant="h5" align="center" sx={{ mb: 6 }} className="container-header">*/}
        {/*      Importer brukere fra AzureAD :)*/}
        {/*    </Typography>*/}
        {/*  </Grid>*/}
        {/*  <Grid item xs={12}>*/}
        {/*    <Typography variant="body1">*/}
        {/*      Importeringen fra AzureAD skjer bare én gang. Åpne og følg bruksanvisningen.*/}
        {/*      <br />*/}
        {/*      <br /> Merk: Du kan bare se tilkoblingskoden en gang, kopier og lim denne inn i deres system.*/}
        {/*    </Typography>*/}
        {/*  </Grid>*/}
        {/*  <Grid item xs={12} sx={{ mt: 3 }}>*/}
        {/*    <Grid container justifyContent="space-between">*/}
        {/*      <Grid item xs={12} sm={12} md={6} sx={{ mb: 2 }}>*/}
        {/*        <Button*/}
        {/*          variant="outlined"*/}
        {/*          className="SecondaryButton"*/}
        {/*          sx={{ height: 40, width: 200 }}*/}
        {/*          href="https://makingview.notion.site/Dr-Cloud-Dokumentasjon-59c30a0e6eea40bf87b86384e5549324?pvs=4"*/}
        {/*          target="_blank"*/}
        {/*        >*/}
        {/*          Bruksanvisning*/}
        {/*        </Button>*/}
        {/*      </Grid>*/}
        {/*      <Grid item xs={12} sm={12} md={6}>*/}
        {/*        <Button*/}
        {/*          variant="contained"*/}
        {/*          className="PrimaryButton"*/}
        {/*          sx={{ height: 40, width: 200 }}*/}
        {/*          onClick={handleClickShowLicense}*/}
        {/*        >*/}
        {/*          Vis Koden*/}
        {/*        </Button>*/}
        {/*      </Grid>*/}
        {/*    </Grid>*/}
        {/*  </Grid>*/}
        {/*</Paper>*/}

        <Paper sx={{ m: 4, p: 3 }}>
          <Grid item xs={12}>
            <Typography variant="h5" align="center" sx={{ mb: 6 }} className="container-header">
              Excel Importering
            </Typography>
          </Grid>
          <Grid item xs={12}>
  <Typography variant="body1">
    Last ned malen, fyll den ut med ansattdata, og laste opp som excel fil. <br />
    Om du skal legge til flere ansatte må du bruke samme excel ark som inneholder alle ansatte du tidligere har importert.<br />
    <a href="https://docs.google.com/spreadsheets/d/1i_pZo55Mj6QJ0Ox7R8wgGhpSg0_QtNS11VBv72sYPLM/edit?usp=sharing" target="_blank" rel="noopener noreferrer">
      Mal for importering ansatte
    </a>
    <br />
  </Typography>
</Grid>

          <Grid item xs={12} sx={{ mt: 3 }}>
            <Grid container justifyContent="space-between">
              <Grid item xs={12} sm={12} md={6} sx={{ mb: 2 }}>
                <input
                  type="file"
                  ref={fileInputRef}
                  onChange={handleFileChange}
                  style={{ display: 'none' }}
                  accept=".xlsx"
                />
                <Button
                  variant="outlined"
                  className="SecondaryButton"
                  sx={{ height: 40, width: 200 }}
                  onClick={() => fileInputRef.current.click()}
                >
                  Velg Excel-filen '.xlsx'
                </Button>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <Button
                  variant="contained"
                  className="secondaryButton"
                  sx={{
                    height: 40,
                    width: 200,
                    ...(file ? {} : {
                      backgroundColor: 'grey.500', // CSV seçili değilse gri arka plan rengi
                      color: 'white', // CSV seçili değilse beyaz yazı rengi
                      '&:hover': {
                        backgroundColor: 'grey.500', // CSV seçili değilse ve üzerine gelindiğinde gri arka plan rengi
                      }
                    })
                  }}
                  onClick={handleFileUpload}
                  disabled={!file}
                >
                  Laste opp
                </Button>
              </Grid>
            </Grid>
          </Grid>

        </Paper>
      </Grid>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modalBox">
          <Typography
            id="modal-modal-title"
            variant="h6"
            color="text.secondary"
          >
            Vennligst kopiér koden og lim den inn på det tilsvarende stedet.
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            {license}
          </Typography>
        </Box>
      </Modal>
    </main>
  );
};

export default IntegrasjonPage;
